import { configureStore } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk';
// import rootReducer from './reducers'; 
import counterStore from './modules/counterStore'
import leftStore from './modules/leftStore'
import drawerStore from './modules/drawerStore'
import useInfoStore from './modules/useInfoStore'
import logoutStore from './modules/logoutStore'
import withdrawModalStore from './modules/withdrawModalStore'
import bindInviteModalStore from './modules/bindInviteModalStore'
import rechangeModalStore from './modules/rechangeModalStore'
import signStore from './modules/signStore'
import changeInviteModalStore from './modules/changeInviteModalStore'
import socializeConfigStore from './modules/socializeConfigStore'
import mintModalStore from './modules/mintModalStore'
import langChangeStore from './modules/langChangeStore'
import langModalStore from './modules/langModalStore'
import setupStore from './modules/setupStore'
import homeNoticeStore from './modules/homeNoticeStore'
import swiStore from './modules/swiStore'
import transferModalStore from './modules/transferModalStore'
// const middleware = [...getDefaultMiddleware(), thunkMiddleware];
export default configureStore({
  reducer: {
    // 注册子模块
    counterStore,
    leftStore,
    drawerStore,
    useInfoStore,
    logoutStore,
    withdrawModalStore,
    bindInviteModalStore,
    rechangeModalStore,
    signStore,
    changeInviteModalStore,
    socializeConfigStore,
    mintModalStore,
    langChangeStore,
    langModalStore,
    setupStore,
    homeNoticeStore,
    swiStore,
    transferModalStore
  },
  // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunkMiddleware),
  middleware: getDefaultMiddleware => [...getDefaultMiddleware(), thunkMiddleware]

})