/*eslint-disable*/
import React, { useEffect } from 'react'
import Header from './Header'
import BgHeader from './BgHeader'
import Bottom from './Bottom'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useAccount, useSignMessage, useConnect } from 'wagmi'
import { show, clearSign, setNewSign } from '../store/modules/signStore'
import { PostExistUserApi, PostSignApi } from '../request/api'
import { message } from 'antd'

import { injected, walletConnect } from 'wagmi/connectors'
import { useReconnect } from 'wagmi'
function Layout () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { address } = useAccount()
  const { connect, connectors } = useConnect()
  useEffect(() => {
    if (!address) {
      connectMetaMask()
    }
  }, [address])
  useEffect(() => {
    // setTimeout(() => {
    //   if (!address) {
    //     connectMetaMask()
    //   }
    // }, 1000)

    // reconnect()
    // connect(connectors[0])
    // 如果不是启动页，跳转到启动页，并传递当前路径
    // if (location.pathname !== '/splash') {
    //   navigate('/splash', { state: { from: location.pathname + location.search } });
    // }
    if (location.pathname === '/') {
      navigate('/home')
      // navigate('/videoStart')
    }
  }, [])

  const clearSign_ = clearSign()

  const { signMessageAsync } =
    useSignMessage()

  const PostExistUser = async () => {
    try {
      const res = await PostExistUserApi({ address: address })
      if (res.data.code === 200) {
        if (res.data.status) {
          signMessageAsync({
            message: 'Welcome to OMNI !',
          })
            .then(async (res) => {
              console.log(res)

              const setSign = setNewSign({ address: address, sign: res })
              // dispatch(setSign)
              const signRes = await PostSignApi({
                address: address,
                signed: res,
              })
              if (signRes.data.code === 200) {
                if (signRes.data.status) {
                  // createRes.data.data.address
                  localStorage.setItem('token', signRes.data.data.token)
                  localStorage.setItem('address', address)
                  // 1. 生成action对象

                  // 2. 提交action进行数据更新
                  dispatch(setSign)
                  message.success(t('signSuccess'))
                  setTimeout(() => {
                    window.location.reload()
                  }, 1000)
                  // window.location.reload()
                } else {
                  message.error(t('signError'))
                  // message.error(signRes.data.msg);
                }
              } else {
                message.error(t('signError'))
              }
            })
            .catch((error) => {
              message.error(t('signError'))
              console.log(error)
            })
        } else {
          if (location.pathname === '/home') {
            console.log(location.pathname)
          } else {
            console.log('跳转')
            navigate('/home')
          }
        }
        // } else {
        //   const createRes = await PostCreateUserApi({ address: address })
        //   if (createRes.data.code === 200) {
        //     // createRes.data.data.address

        //     signMessageAsync().then(async (res) => {
        //       console.log(res);
        //       const setSign = setNewSign({ address: address, sign: res })
        //       // dispatch(setSign)
        //       const signRes = await PostSignApi({ address: address, signed: res })
        //       if (signRes.data.code === 200) {
        //         if (signRes.data.data.status) {
        //           // createRes.data.data.address
        //           localStorage.setItem("token", res);
        //           localStorage.setItem("address", address);
        //           // 1. 生成action对象

        //           // 2. 提交action进行数据更新
        //           dispatch(setSign)
        //           message.success(t('signSuccess'))
        //           window.location.reload()
        //         } else {
        //           message.error(t('signError'))
        //           // message.error(signRes.data.msg);
        //         }

        //       } else {
        //         message.error(t('signError'))
        //       }
        //     }).catch((error) => {
        //       message.error(t('signError'))
        //       console.log(error)
        //     })

        //   } else {
        //     message.error(createRes.data.msg);
        //   }

        // }
      } else {
        message.error(res.data.msg)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (address) {
      // 签名
      if (
        localStorage.getItem('address') != address ||
        !localStorage.getItem('token')
      ) {
        // 2. 提交action进行数据更新
        dispatch(clearSign_)
        localStorage.removeItem('token') && localStorage.removeItem('address')
        PostExistUser()
      }
    }
  }, [address])

  // useEffect(() => {
  //   console.log('监听钱包', window.ethereum)
  //   // 监听 TP 钱包地址变更
  //   if (window.ethereum) {
  //     const handleAccountsChanged = (accounts) => {
  //       console.log('监听钱包变更', accounts)

  //       // window.location.reload()
  //     }

  //     window.ethereum.on('accountsChanged', handleAccountsChanged)

  //     return () => {
  //       if (window.ethereum) {
  //         window.ethereum.removeListener('accountsChanged', handleAccountsChanged)
  //       }
  //     }
  //   }
  // }, [])

  const connectMetaMask = () => {
    connect({
      connector: injected(),
      chainId: 56
    })
  }

  const connectWalletConnect = () => {
    connect({
      connector: walletConnect({
        projectId: 'bc5ae4b0a9d73ac61eed542f5fe6ac55'
      }),
      chainId: 56
    })
  }

  const IsHeader = [
    '/home',
    '/record',
    '/nftMarket',
    '/buyNft/1',
    '/buyNft/2',
    '/buyNft/3',
    '/buyNft/4',
    '/buyNft/5',
    '/nftPortfolio',
    // '/myAssets',
    '/dao',
    '/miningMachine',
    '/myAI',
    '/nftTokenMining',
    '/myNode',
    '/myNodeInner',
    '/cardGame',
    '/myCard',
    '/myNboBonusPlan',
    '/trade',
    '/createOrder',
    '/sell',
    '/myOrder',
    '/myCom',
    '/more',
    '/launchPad',
    '/minePool',
    '/inviteFriend',
    '/ecoMarket',
    '/communityEcology',
    '/aiQuantification'
  ]
  const IsBottom = [
    '/home',
    '/trade',
    '/myAssets',
    '/my',
    '/launchPad',
    '/minePool',
    '/communityEcology'
  ]
  const normal_Header = [
    // '/foundation'
  ]
  return (
    <div className="min-h-screen bg-[#000000] text-white">
      {IsHeader.includes(location.pathname) ? (
        <BgHeader></BgHeader>
      ) : normal_Header.includes(location.pathname) ? (
        <Header></Header>
      ) : (
        ''
      )}
      <main>
        {' '}
        <Outlet />
      </main>
      {IsBottom.includes(location.pathname) ? (
        <Bottom></Bottom>) : ''}
    </div>
  )
}
export default Layout
