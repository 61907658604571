/*eslint-disable*/
import React, { lazy, Suspense } from 'react'
const Home = lazy(() => import('../page/Home'))

const MyNft = lazy(() => import('../page/MyNFT'))

const MyAssets = lazy(() => import('../page/MyAssets'))

const AssetsRecord = lazy(() => import('../page/Record/AssetsRecord'))

const Notice = lazy(() => import('../page/Notice/Notice'))
const NoticeChild = lazy(() => import('../page/Notice/NoticeChild'))

const MyAI = lazy(() => import('../page/MyAI'))
const NftTokenMining = lazy(() => import('../page/NftTokenMining'))
const MyNode = lazy(() => import('../page/MyNode'))
const MyAIRobot = lazy(() => import('../page/MyAIRobot'))
const AIFinance = lazy(() => import('../page/Record/AIFinance'))
const ReferrerBenefits = lazy(() => import('../page/Record/ReferrerBenefits'))
const ComBenefits = lazy(() => import('../page/Record/ComBenefits'))
const DirectPushBenefits = lazy(() =>
  import('../page/Record/DirectPushBenefits')
)
const NFTMiningDetail = lazy(() => import('../page/Record/NFTMiningDetail'))
const AIFinanceDetail = lazy(() => import('../page/Record/AIFinanceDetail'))
const MyNboBonusPlan = lazy(() => import('../page/MyNboBonusPlan'))
const MyBonusPlan = lazy(() => import('../page/MyBonusPlan'))
const BonusPlanDetail = lazy(() => import('../page/Record/BonusPlanDetail'))
const CommBenefits = lazy(() => import('../page/Record/CommBenefits'))
const DirectPushList = lazy(() => import('../page/Record/DirectPushList'))
const DestructionPool = lazy(() => import('../page/Record/DestructionPool'))
const Chamber = lazy(() =>
  import('../page/Chamber.jsx')
)
const FeedbackChild = lazy(() =>
  import('../page/FeedbackChild.jsx')
)
const Proposal = lazy(() => import('../page/Proposal.jsx'))
const USDTDetail = lazy(() => import('../page/Record/USDTDetail'))
const OMNIDetail = lazy(() => import('../page/Record/OMNIDetail.jsx'))
const SNBDetail = lazy(() => import('../page/Record/SNBDetail.jsx'))
const MINIDetail = lazy(() => import('../page/Record/MINIDetail.jsx'))
const FDVDetail = lazy(() => import('../page/Record/FDVDetail.jsx'))
const Ico = lazy(() => import('../page/Ico'))
const Nft = lazy(() => import('../page/Nft'))
const MyCom = lazy(() => import('../page/MyCom'))
const Record = lazy(() => import('../page/Recording'))
const Trade = lazy(() => import('../page/Trade'))
const CreateOrder = lazy(() => import('../page/CreateOrder'))
const Sell = lazy(() => import('../page/Sell'))
const MyOrder = lazy(() => import('../page/MyOrder'))
const MyNodeInner = lazy(() => import('../page/MyNodeInner'))
const CardGame = lazy(() => import('../page/CardGame'))
const MyCard = lazy(() => import('../page/MyCard'))
const My = lazy(() => import('../page/My'))
const MyTeam = lazy(() => import('../page/MyTeam'))
const IncomeDetail = lazy(() => import('../page/IncomeDetail'))
const SnbPrice = lazy(() => import('../page/Record/SNBPrice.jsx'))
const FdvPrice = lazy(() => import('../page/Record/FDVPrice.jsx'))

const NodeIncome = lazy(() => import('../page/Record/NodeIncome.jsx'))
const NodeBonusDetail = lazy(() => import('../page/Record/NodeBonusDetail.jsx'))
const CardIncome = lazy(() => import('../page/Record/CardIncome.jsx'))
const CardDirectPushIncome = lazy(() => import('../page/Record/CardDirectPushIncome.jsx'))
const CardTeamIncome = lazy(() => import('../page/Record/CardTeamIncome.jsx'))
const Swap = lazy(() => import('../page/Swap.jsx'))
const More = lazy(() => import('../page/More.jsx'))
const LaunchPad = lazy(() => import('../page/LaunchPad.jsx'))
const MinePool = lazy(() => import('../page/MinePool.jsx'))
const InviteFriend = lazy(() => import('../page/InviteFriend'))
const EcoMarket = lazy(() => import('../page/EcoMarket'))
const CommunityEcology = lazy(() => import('../page/CommunityEcology.jsx'))
const AiQuantification = lazy(() => import('../page/AiQuantification.jsx'))
const MyAiCom = lazy(() => import('../page/MyAiCom.jsx'))
const AiIncomeDetail = lazy(() => import('../page/Record/AiIncomeDetail.jsx'))
const AiDirectPushRevenueDetails = lazy(() => import('../page/Record/AiDirectPushRevenueDetails.jsx'))
const AiTeamIncomeDetail = lazy(() => import('../page/Record/AiTeamIncomeDetail.jsx'))
const OMNIDAODetail = lazy(() => import('../page/Record/OMNIDAODetail.jsx'))
// const VideoStart = lazy(() => import('../page/Video/VideoStart.jsx'))

import Layout from '../components/Layout'
import { getToken } from '../utils/token.js'
import { Skeleton } from 'antd-mobile'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import Loading from './Loading'
// import MyAssets from '../page/MyAssets'
// import BuyNFT from '../page/BuyNFT';
function RequireAuth ({ children }) {
  const authed = getToken()
  return authed ? ( // 判断 authed 中登录状态是否为 true
    children // 嵌套传的
  ) : (
    <Navigate to="/login" replace /> // 跳转到登录
  )
}
const SkeletonLoading = () => {
  return (
    <div>
      <Skeleton.Title animated />
      <Skeleton.Paragraph lineCount={10} animated />
    </div>
  )
}
const BaseRouter = () => (
  <Router>
    <Routes>
      {/* <Route path="/home" element={<Home />} />  */}
      <Route path="/" element={<Layout />}>
        {/* <Route path="/splash" element={<VideoStart />} /> */}
        <Route
          path="/home"
          element={
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          }
        />
        {/* <Route
          path="/myNft"
          element={
            <Suspense fallback={<Loading />}>
              <MyNft />
            </Suspense>
          }
        /> */}
        <Route
          path="/noticeList"
          element={
            <Suspense fallback={<Loading />}>
              <Notice />
            </Suspense>
          }
        />
        <Route
          path="/noticeDetail"
          element={
            <Suspense fallback={<Loading />}>
              <NoticeChild />
            </Suspense>
          }
        />
        <Route
          path="/myAssets"
          element={
            <Suspense fallback={<Loading />}>
              <MyAssets />
            </Suspense>
          }
        />
        <Route
          path="/recordList"
          element={
            <Suspense fallback={<Loading />}>
              <AssetsRecord />
            </Suspense>
          }
        />
        <Route
          path="/myAI"
          element={
            <Suspense fallback={<Loading />}>
              <MyAI />
            </Suspense>
          }
        />
        {/* <Route
          path="/nftTokenMining"
          element={
            <Suspense fallback={<Loading />}>
              <NftTokenMining />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/myNode"
          element={
            <Suspense fallback={<Loading />}>
              <MyNode />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/myAI/RobotInfo"
          element={
            <Suspense fallback={<Loading />}>
              <MyAIRobot />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/aiFinance"
          element={
            <Suspense fallback={<Loading />}>
              <AIFinance />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/referrerBenefits"
          element={
            <Suspense fallback={<Loading />}>
              <ReferrerBenefits />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/comBenefits"
          element={
            <Suspense fallback={<Loading />}>
              <ComBenefits />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/nftMiningDetail/:id"
          element={
            <Suspense fallback={<Loading />}>
              <NFTMiningDetail />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/aiFinanceDetail/:id"
          element={
            <Suspense fallback={<Loading />}>
              <AIFinanceDetail />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/myNboBonusPlan"
          element={
            <Suspense fallback={<Loading />}>
              <MyNboBonusPlan />
            </Suspense>
          }
        />
        <Route
          path="/myBonusPlan"
          element={
            <Suspense fallback={<Loading />}>
              <MyBonusPlan />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/bonusPlanDetail/:id"
          element={
            <Suspense fallback={<Loading />}>
              <BonusPlanDetail />
            </Suspense>
          }
        />
        <Route
          path="/directPushBenefits"
          element={
            <Suspense fallback={<Loading />}>
              <DirectPushBenefits />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/commBenefits"
          element={
            <Suspense fallback={<Loading />}>
              <CommBenefits />
            </Suspense>
          }
        /> */}
        <Route
          path="/directPushList"
          element={
            <Suspense fallback={<Loading />}>
              <DirectPushList />
            </Suspense>
          }
        />
        {/* <Route
          path="/destructionPool"
          element={
            <Suspense fallback={<Loading />}>
              <DestructionPool />
            </Suspense>
          }
        /> */}
        <Route
          path="/usdtDetail"
          element={
            <Suspense fallback={<Loading />}>
              <USDTDetail />
            </Suspense>
          }
        />
        <Route
          path="/omnDetail"
          element={
            <Suspense fallback={<Loading />}>
              <OMNIDetail />
            </Suspense>
          }
        />

        <Route
          path="/snbDetail"
          element={
            <Suspense fallback={<Loading />}>
              <SNBDetail />
            </Suspense>
          }
        />
        <Route
          path="/fdvDetail"
          element={
            <Suspense fallback={<Loading />}>
              <FDVDetail />
            </Suspense>
          }
        />
        {/* <Route
          path="/ico"
          element={
            <Suspense fallback={<Loading />}>
              <Ico />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/nft"
          element={
            <Suspense fallback={<Loading />}>
              <Nft />
            </Suspense>
          }
        /> */}
        <Route
          path="/myCom"
          element={
            <Suspense fallback={<Loading />}>
              <MyCom />
            </Suspense>
          }
        />
        <Route
          path="/record"
          element={
            <Suspense fallback={<Loading />}>
              <Record />
            </Suspense>
          }
        />
        <Route
          path="/my"
          element={
            <Suspense fallback={<Loading />}>
              <My />
            </Suspense>
          }
        />
        <Route
          path="/myTeam"
          element={
            <Suspense fallback={<Loading />}>
              <MyTeam />
            </Suspense>
          }
        />
        <Route
          path="/incomeDetail"
          element={
            <Suspense fallback={<Loading />}>
              <IncomeDetail />
            </Suspense>
          }
        />
        <Route
          path="/snbPrice"
          element={
            <Suspense fallback={<Loading />}>
              <SnbPrice />
            </Suspense>
          }
        />

        <Route
          path="/fdvPrice"
          element={
            <Suspense fallback={<Loading />}>
              <FdvPrice />
            </Suspense>
          }
        />
        <Route
          path="/trade"
          element={
            <Suspense fallback={<Loading />}>
              <Trade />
            </Suspense>
          }
        />
        <Route
          path="/createOrder"
          element={
            <Suspense fallback={<Loading />}>
              <CreateOrder />
            </Suspense>
          }
        />
        <Route
          path="/sell/:id"
          element={
            <Suspense fallback={<Loading />}>
              <Sell />
            </Suspense>
          }
        />
        <Route
          path="/myOrder"
          element={
            <Suspense fallback={<Loading />}>
              <MyOrder />
            </Suspense>
          }
        />
        <Route
          path="/myNode"
          element={
            <Suspense fallback={<Loading />}>
              <MyNode />
            </Suspense>
          }
        />
        <Route
          path="/myNodeInner"
          element={
            <Suspense fallback={<Loading />}>
              <MyNodeInner />
            </Suspense>
          }
        />
        <Route
          path="/cardGame"
          element={
            <Suspense fallback={<Loading />}>
              <CardGame />
            </Suspense>
          }
        />
        <Route
          path="/myCard"
          element={
            <Suspense fallback={<Loading />}>
              <MyCard />
            </Suspense>
          }
        />
        <Route
          path="/nodeIncome"
          element={
            <Suspense fallback={<Loading />}>
              <NodeIncome />
            </Suspense>
          }
        />
        <Route
          path="/nodeBonusDetail"
          element={
            <Suspense fallback={<Loading />}>
              <NodeBonusDetail />
            </Suspense>
          }
        />
        <Route
          path="/cardIncome"
          element={
            <Suspense fallback={<Loading />}>
              <CardIncome />
            </Suspense>
          }
        />
        <Route
          path="/cardDirectPushIncome"
          element={
            <Suspense fallback={<Loading />}>
              <CardDirectPushIncome />
            </Suspense>
          }
        />
        <Route
          path="/cardTeamIncome"
          element={
            <Suspense fallback={<Loading />}>
              <CardTeamIncome />
            </Suspense>
          }
        />
        <Route
          path="/miniDetail"
          element={
            <Suspense fallback={<Loading />}>
              <MINIDetail />
            </Suspense>
          }
        />
        <Route
          path="/chamber"
          element={
            <Suspense fallback={<Loading />}>
              <Chamber />
            </Suspense>
          }
        />
        <Route
          path="/feedbackChild/:id"
          element={
            <Suspense fallback={<Loading />}>
              <FeedbackChild />
            </Suspense>
          }
        />
        <Route
          path="/proposal"
          element={
            <Suspense fallback={<Loading />}>
              <Proposal />
            </Suspense>
          }
        />
        <Route
          path="/swap"
          element={
            <Suspense fallback={<Loading />}>
              <Swap />
            </Suspense>
          }
        />
        <Route
          path="/more"
          element={
            <Suspense fallback={<Loading />}>
              <More />
            </Suspense>
          }
        />
        <Route
          path="/launchPad"
          element={
            <Suspense fallback={<Loading />}>
              <LaunchPad />
            </Suspense>
          }
        />
        <Route
          path="/minePool"
          element={
            <Suspense fallback={<Loading />}>
              <MinePool />
            </Suspense>
          }
        />
        <Route
          path="/inviteFriend"
          element={
            <Suspense fallback={<Loading />}>
              <InviteFriend />
            </Suspense>
          }
        />
        <Route
          path="/ecoMarket"
          element={
            <Suspense fallback={<Loading />}>
              <EcoMarket />
            </Suspense>
          }
        />
        <Route
          path="/communityEcology"
          element={
            <Suspense fallback={<Loading />}>
              <CommunityEcology />
            </Suspense>
          }
        />
        <Route
          path="/aiQuantification"
          element={
            <Suspense fallback={<Loading />}>
              <AiQuantification />
            </Suspense>
          }
        />
        <Route
          path="/myAiCom"
          element={
            <Suspense fallback={<Loading />}>
              <MyAiCom />
            </Suspense>
          }
        />
        <Route
          path="/aiDirectPushRevenueDetails"
          element={
            <Suspense fallback={<Loading />}>
              <AiDirectPushRevenueDetails />
            </Suspense>
          }
        />
        <Route
          path="/aiTeamIncomeDetail"
          element={
            <Suspense fallback={<Loading />}>
              <AiTeamIncomeDetail />
            </Suspense>
          }
        />
        <Route
          path="/aiIncomeDetail"
          element={
            <Suspense fallback={<Loading />}>
              <AiIncomeDetail />
            </Suspense>
          }
        />
        <Route
          path="/omnidaoDetail"
          element={
            <Suspense fallback={<Loading />}>
              <OMNIDAODetail />
            </Suspense>
          }
        />



      </Route>
    </Routes>
  </Router>
)

export default BaseRouter
